<template>
  <div id="app">
    <div class="web-header-page">
      <div class="header-content">
        <div class="leftbox">
          <div class="logo-image">
            <img src="https://cdn.lnxall.com/logo%402x.png" />
            <!-- <img src="/img/lnxde.png" v-if="marchPath('energy')"/>
            <img src="/img/logo@2x.png" v-else/> -->
          </div>
        </div>
        <div class="rightbox" @click="showMenu">
          <img src="https://cdn.lnxall.com/mobile/menu.png" alt="" srcset="" />
        </div>
      </div>
    </div>
    <router-view />
    <div class="web-footer-page">
      <div class="footer-content">
        <div style="padding: 20px">
          <div class="logo">
            <img src="https://cdn.lnxall.com/logo%402x.png" />
          </div>
          <div class="leet-item" style="margin-top: 40px">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAAWlBMVEX///8AAAD///////8KEyT///////////9ARlQwN0b////////////////////R1NfFxss5Pk3///////////////////////////////////////////////8gnk2KAAAAHnRSTlPMAEcHzE80HszMpol5YD3MzMzHvraSgW5UJxkPqmhno8Z6AAAAdklEQVQI1zXOSRaDMAwE0UKeMAYzz8n9r5lYJL3S39QTVdklculRaFqAzfy4oUsPT+jruodT2UFt7Qjdn6O1Ed7KDEOMA2SladA15imHGWAOWjay+wkmv4upCAukDJJgCQhA65x+JlSulNYVeDlN3V6OQ/z9PT97RAP5pwU+BQAAAABJRU5ErkJggg==">
            <div class="title">
              杭州市西湖区华星路99号创业大厦（华星路）西7楼
            </div>
          </div>
          <div class="leet-item">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAAXVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9RKvvlAAAAH3RSTlMAzA0GxJMvontuaGFTSzQfGRTBubSqpHNYKSWLg0M/u7/WawAAAHdJREFUCNdNzdkOgzAMRNE7JGGnJOzQ5f8/s3JbVM6LZdmj4Su7RS5yacl/B8DlW6EK6EftkLroGrWwSOvnuab0kVl6WKRWlw0VlXRg7jOhIHnvMJvcOkGjEtO/OKwkqOEiK1TvYSr7cw8yA6d2lPzzH3Bdm2y+Aa5HA/vNiPOCAAAAAElFTkSuQmCC" />
            <div class="title">0571-87698273</div>
          </div>
          <div class="leet-item">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAMAAABlXnzoAAAARVBMVEX///////////////////////////8AAAD////////////////////////////////////////////////////////////l9IKsAAAAF3RSTlPMWFN4hykFAL+cgkU2Ew3Gs6swIpJgQR1TFpoAAABTSURBVAjXVc5JDoAgAMDAKrILsqj/f6ohIQi9za1ouY+q5mXqxJtfqRCD6LgeFXAiStvkQr0FB2ZTPlkZxUUj5FOp3UAn2AwTodOt9OuGLttI6g/4yAJlIMPRuQAAAABJRU5ErkJggg==" />
            <div class="title">Sales@Lnxall.com</div>
          </div>
        </div>
      </div>
      <div class="footer-description">
        版权所有©&nbsp;浙江简捷物联科技有限公司 <br />浙ICP备20014292号-1
      </div>
    </div>
    <van-popup
      position="left"
      closeable
      :style="{ height: '100%', width: '100%' }"
      v-model="show"
    >
      <div class="leet-menue-content">
        <div class="leftbox">
          <div class="menue-item" @click="handleUrl('index')">首页</div>
          <div class="menue-item" @click="handleUrl('carbon')">零碳数智园区</div>
          <div class="menue-item" @click="handleUrl('energy')">数智能源</div>
          <div class="menue-item" @click="handleUrl('basis')">数智化基础</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Icon, Popup } from "vant";
export default {
  name: "app-page",
  components: {
    [Icon.name]: Icon,
    [Popup.name]: Popup,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {
    showMenu() {
      this.show = true;
    },
    handleUrl(url) {
      this.$router.push({ path: url });
      this.show = false;
    },
    marchPath(value) {
      let url = this.$route.path;
      return url.indexOf(value) > -1 ? true : false;
    },
  },
  destroyed() {},
};
</script>
<style lang="less">
.leet-menue-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  .leftbox {
    width: calc(100% - 50px);
    height: 100%;
    box-sizing: border-box;
    background-color: #1b4da4;
    .menue-item {
      height: 48px;
      padding: 0 20px;
      color: #ffffff;
      line-height: 48px;
      font-size: 16px;
      border-bottom: 1px solid #2c5cb1;
    }
  }
  .rightbox {
    height: 100%;
    background-color: #ffffff;
    width: 50px;
    box-sizing: border-box;
    text-align: center;
  }
}
#app {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .web-header-page {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding: 10px 20px;
    .header-content {
      height: 40px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      .leftbox {
        position: relative;
        .logo-image {
          width: 65px;
          height: 30px;
          position: absolute;
          top: 0px;
          img {
            width: 100%;
          }
        }
      }
      .rightbox {
        position: relative;
        img {
          width: 36px;
        }
      }
    }
  }
  .web-footer-page {
    box-sizing: border-box;
    background: #060c18;
    width: 100%;
    .footer-content {
      width: 100%;
      box-sizing: border-box;
      .logo {
        width: 65px;
        height: 30px;
        img {
          width: 100%;
        }
      }
      .leet-item {
        height: 17px;
        line-height: 17px;
        position: relative;
        color: rgba(255, 255, 255, 0.8);
        font-size: 12px;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 10px;
        img {
          width: 14px;
          height: 14px;
          position: absolute;
          top: 1px;
        }
        .title {
          position: absolute;
          left: 25px;
        }
      }
    }
    .footer-description {
      height: 56px;
      box-sizing: border-box;
      background: #040811;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
    }
  }
}
</style>
